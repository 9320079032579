export const darkTheme = {
  bg: "#000000",
  bgLight: "#264a59",
  primary: "#264a59",
  text_primary: "#F2F3F4",
  text_secondary: "#b1b2b3",
  //   card: "#403939",
  card_light: "#000000",
  button: "#264a59",
  white: "#FFFFFF",
  black: "#000000",
};

export const lightTheme = {
  bg: "#FFFFFF",
  bgLight: "#f0f0f0",
  primary: "#fcc3a3",
  text_primary: "#111111",
  text_secondary: "#2b2c2e",
  card: "#FFFFFF",
  button: "#5c5b5b",
};